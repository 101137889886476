// 自律公约
// 总则
export const GeneralProvisions = `
  <p class="text">偶藏平台（以下简称“平台”）一直致力于为广大用户提供一个健康、和谐、开放、友爱的平台。我们深知规范、平等、积极的社区环境对于用户和平台的重要意义，并为此依据相关法律法规制定本《偶藏社区自律公约》。</p>
  <p class="text">《偶藏社区自律公约》是用户使用平台服务的指引性规范和行为准则，适用于使用平台客户端应用程序及相关网站（https://www.ocang.com.cn/）的所有用户。用户如果违反本公约将面临相应的处罚，处罚类型包括但不限于删除或屏蔽违规内容、对违规账号禁言或封禁等；构成违法犯罪的，平台将积极配合执法及司法机关的工作。平台鼓励广大用户对违规内容或行为进行举报，共建平台健康生态。</p>
`

export const PlatformAdvocacy = `
  <p class="text">
    <span class="span_dot"></span>
    我们呼吁建立平等友爱的偶藏社区，尊重偶藏社区内的其他用户。关爱未成年人群体，关照老年人群体，尊重性别平等；不攻击、谩骂、侮辱、诽谤、歧视他人，不侵犯他人合法权益，共同营造温暖和谐的社区氛围；
    我们鼓励原创、优质的内容；
  </p>
  <p class="text">
    <span class="span_dot"></span>
    我们鼓励原创、优质的内容；
  </p>
  <p class="text">
    <span class="span_dot"></span>
      我们建议重视文字的正确使用，避免出现错别字，减少用拼音首字母缩写表达，自觉遵守语言文字规范；
  </p>
  <p class="text">
    <span class="span_dot"></span>
      我们建议提高网络安全防范意识，对网络交友、诱导赌博、贷款、返利、中奖、网络兼职点赞员等网络诈骗高发领域及行为应提高警惕。如发觉异常，可随时向平台举报；
  </p>
  <p class="text">
    <span class="span_dot"></span>
    我们鼓励发布经过科学论证的内容，不造谣、不传谣。
  </p>
`

export const PlatformProhibition = [
  {
    title: '一、暴力与犯罪行为',
    id: 1,
    subtitles: [
      {
        id: '1-1',
        text: '煽动与实施暴力',
        content: `
          <p class="text">平台坚决反对各种形式的暴力，禁止用户利用平台的服务煽动、宣扬或美化暴力，严禁用户利用平台服务组织或参与意图造成人员伤亡、重大财产损失、公共设施损坏、社会秩序混乱等严重的暴力活动。平台禁止用户发布煽动、描述或展示可能给他人造成人身伤害、财产损失，或可能对公共安全和秩序、社会管理秩序等造成威胁或伤害的行为的内容。</p>
          <p class="text">平台禁止制作、发布、传播或借助平台实施以下煽动暴力、实施或者威胁实施暴力的内容：</p>
          <p class="text"><span class="span_dot"></span>组织、煽动对不特定多数人或特定群体造成人身伤害、财产损失的言论，如发布以特定或不特定的公众、群体或者设施为目标的暴力威胁；</p>
          <p class="text"><span class="span_dot"></span>组织、煽动群体性暴力活动的内容，如暴力破坏公共设施、公共交通工具，损害公共财产等；</p>
          <p class="text"><span class="span_dot"></span>组织、煽动不特定用户在平台或在平台之外以暴力方式干扰公共秩序；</p>
          <p class="text"><span class="span_dot"></span>组织、煽动不特定用户加入或实施涉黑性质活动及违法暴力活动；</p>
          <p class="text"><span class="span_dot"></span>宣扬暴力伤害行为，例如：宣扬聚众暴力犯罪、暴力袭击、校园暴力；</p>
          <p class="text"><span class="span_dot"></span>意图美化暴力、美化暴力实施者，或对其进行宣扬、推崇，或为其谋求支持等；</p>
          <p class="text"><span class="span_dot"></span>宣扬以暴制暴，宣扬极端的复仇心理和行为；</p>
          <p class="text"><span class="span_dot"></span>寻求或提供雇凶打架、杀人等暴力服务；</p>
          <p class="text"><span class="span_dot"></span>暴力犯罪工具的制造、使用说明。</p>
        `,
      },
      {
        id: '1-2',
        text: '违禁与管制物品',
        content: `
          <p class="text">为共同营造安全的社区环境、遵守国家法律法规的规定，禁止任何个人或组织在平台上以明示或暗示、直接或间接的方式贩卖、交易、赠送法律法规规定的违禁品和管制物品或发布与之相关的内容（新闻媒体公开报道的除外）。此处的违禁与管制物品包括但不限于枪支弹药、爆炸物；管制器具及其他攻击性器械；剧毒物品、放射性物品等。</p>
          <p class="text">（1）枪支弹药、爆炸物</p>
          <p class="text">平台禁止描述、宣传或交易枪支及其配件、弹药或爆炸性物质，禁止展示“武器”的制造说明，但武器作为博物馆藏品的一部分，或警察配枪、军事阅兵、演习或战争等真实场景使用武器，或在安全受控的环境中使用武器（例如：射击场）除外。</p>
          <p class="text">平台禁止制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>展示、描述枪支、弹药等武器或爆炸性物质的内容；</p>
          <p class="text"><span class="span_dot"></span>提供购买、出售、交易渠道或推销使用枪支、弹药等武器或爆炸性物质或介绍其相关制造方法的内容；</p>
          <p class="text"><span class="span_dot"></span>其他违规展示枪支、弹药、爆炸性物质的内容；</p>
          <p class="text">（2）管制器具及其他攻击性器械</p>
          <p class="text">平台禁止制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>展示、描述管制器具、刀械的内容；</p>
          <p class="text"><span class="span_dot"></span>提供购买、出售、交易渠道或推销使用管制器具、刀械或介绍其相关制造方法的内容；</p>
          <p class="text"><span class="span_dot"></span>其他违规展示管制器具及攻击性器械的内容；</p>
          <p class="text">（3）剧毒物品、放射性物品</p>
          <p class="text">平台禁止制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>提供购买、出售、交易渠道或招揽使用剧毒、放射性物品以及其他有毒、有害性物质或介绍其相关制造方法的内容。</p>
        `,
      },
      {
        id: '1-3',
        text: '恐怖主义与极端主义',
        content: `
          <p class="text">平台坚决反对各类形式的恐怖主义和极端主义内容，坚决抵制任何人利用平台服务宣扬恐怖主义和极端主义思想、煽动恐怖主义和极端主义活动，禁止发布对恐怖主义、极端主义进行美化、传播其主张、展示恐怖或极端活动的内容。</p>
          <p class="text">平台禁止制作、发布或传播以下宣扬恐怖主义、极端主义的内容：</p>
          <p class="text"><span class="span_dot"></span>煽动他人加入恐怖主义、极端主义组织或参与、实施恐怖主义、极端主义活动的内容；</p>
          <p class="text"><span class="span_dot"></span>展示境内外恐怖主义、极端主义组织或展示恐怖或极端主义活动的内容；传播宣扬恐怖主义、极端主义的图文，或通过讲授、发布信息等方式宣扬恐怖主义、极端主义的内容；</p>
          <p class="text"><span class="span_dot"></span>宣扬恐怖主义、极端主义而展示、穿着、佩戴宣扬恐怖主义、极端主义服饰、标志的内容；</p>
          <p class="text"><span class="span_dot"></span>传播有目的、有计划、有组织地通过自焚、人体炸弹、打砸抢烧等手段发动的暴力恐怖袭击活动内容），或转发对这些活动进行歪曲事实真相的片面报道的内容；</p>
          <p class="text"><span class="span_dot"></span>展示含有代表恐怖组织、极端组织或组织成员的名称、符号、徽标、旗帜、标语、手势、敬礼、插图、肖像、歌曲、音乐、歌词或其他物件的内容，但新闻媒体公开报道的除外。</p>
        `,
      },
      {
        id: '1-4',
        text: '危险人物与组织',
        content: `
          <p class="text">为了防止和斩断现实中可能发生的伤害，平台不欢迎任何从事严重暴力活动，对国家安全、公共安全以及公民的人身安全造成严重伤害或威胁的危险人物与组织使用平台服务。一经发现，平台有权停止对其提供服务。危险人物与组织包括但不限于组织、领导、参与下列任何活动的组织或个人：</p>
          <p class="text"><span class="span_dot"></span>恐怖活动或极端主义活动；</p>
          <p class="text"><span class="span_dot"></span>意图或已经致多人死亡的故意杀人犯罪；</p>
          <p class="text"><span class="span_dot"></span>人口拐卖与贩运；</p>
          <p class="text"><span class="span_dot"></span>黑社会性质组织犯罪；</p>
          <p class="text"><span class="span_dot"></span>暴力性侵害儿童的犯罪；</p>
          <p class="text"><span class="span_dot"></span>其他从事严重暴力活动的情形；</p>
          <p class="text">此外，平台还禁止用户发布以下对危险人物与组织进行赞美或表达支持的内容：</p>
          <p class="text"><span class="span_dot"></span>分享代表该等组织/个人的标志，除非是为了对其进行谴责或就相关内容开展中立讨论；</p>
          <p class="text"><span class="span_dot"></span>宣扬该等组织/个人或其行为、“事迹”的内容，并表达支持态度；</p>
          <p class="text"><span class="span_dot"></span>为上述任何组织或个人或其所进行的任何活动募集资金、招募人员、寻求支持；</p>
          <p class="text"><span class="span_dot"></span>赞扬、支持或美化已被认定为恐怖袭击、大规模谋杀或连环谋杀、恶性黑社会性质组织犯罪的事件；</p>
          <p class="text"><span class="span_dot"></span>支持、美化、纪念危险人物与组织或相关活动；</p>
          <p class="text"><span class="span_dot"></span>依据或参考真实事件，二次创作危险人物与组织、活动相关的内容等。</p>
        `,
      },
      {
        id: '1-5',
        text: '展示或宣扬违法犯罪活动',
        content: `
          <p class="text">平台禁止制作、发布或传播扰乱经济秩序、妨碍社会管理秩序等侵害公共利益的违法内容或利用平台实施相关违法犯罪活动的行为，或发布为此类活动进行宣传、推广的内容。如有用户投诉或平台发现用户发布的信息中含此类违规内容的，平台会将相关内容及账号予以处罚，避免给其他用户带来人身伤害和财产损失。对于构成违法犯罪的，平台将配合执法及司法机关进行处理。</p>
          <p class="text">（1）违规或违法交易</p>
          <p class="text">平台禁止发布含有推广、宣传、售卖以下“商品或服务”的内容：</p>
          <p class="text"><span class="span_dot"></span>宣传、推广非法医疗服务，如代孕、试药、人体器官买卖、胎儿性别鉴定等内容；</p>
          <p class="text"><span class="span_dot"></span>宣传、推广传销组织及其产品或活动，煽动、引导、逼迫加入传销组织等行为的内容；</p>
          <p class="text"><span class="span_dot"></span>制作、买卖、推广假冒伪劣物品及相关服务，包括假币、假证、高仿商品，倒卖发票、伪造文件印章等内容；</p>
          <p class="text"><span class="span_dot"></span>宣传、推广其他非法交易服务，如私家侦探、买卖人口、卖淫嫖娼、非法领养或送养儿童、买卖血液等非法内容；</p>
          <p class="text"><span class="span_dot"></span>宣传、推广非法的涉外婚介、劳务服务的内容；</p>
          <p class="text"><span class="span_dot"></span>需按照监管要求取得资质证照且经平台认证后发布、但未取得相应资质证照或经认证情况下发布的相关内容，包括但不限于药品、医疗器械、医疗机构的推广内容，涉及金融等专业产品或服务的推广内容等；</p>
          <p class="text"><span class="span_dot"></span>发布平台禁止的其他销售、推广内容，包括但不限于：烟草广告、两性用品广告等；</p>
          <p class="text"><span class="span_dot"></span>发布违反国家法律法规、部门规章等规范性文件的其他交易内容。</p>
          <p class="text"><span class="span_dot"></span>未经平台许可，禁止散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息等内容。</p>
          <p class="text">（2）赌博等非法射幸活动</p>
          <p class="text">平台禁止用户利用平台服务实施组织赌博活动、招募赌博人员等违法犯罪行为，禁止用户制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>组织赌博活动、展示赌博行为（含线上及线下、虚拟及实体形式）的内容；</p>
          <p class="text"><span class="span_dot"></span>宣传赌博服务，或被视为赌场、体育博彩或任何其他赌博活动广告的内容；</p>
          <p class="text"><span class="span_dot"></span>宣传与赌博相关的软件、网址，或其他赌博服务渠道的内容；</p>
          <p class="text"><span class="span_dot"></span>展示赌博设备、器具，以及赌博技巧的内容；</p>
          <p class="text"><span class="span_dot"></span>展示、宣传与赌博相关的其他内容。</p>
          <p class="text">（3）毒品、管制药品</p>
          <p class="text">平台禁止展示、描述、宣传、推广或贩卖毒品及其制品、管制药品或介绍其相关制造方法的内容。禁止用户制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>描述或展示或美化吸毒贩毒、制作或买卖毒品活动，或展示、介绍、美化、模仿吸毒行为的内容；</p>
          <p class="text"><span class="span_dot"></span>描述或宣传毒品、展示使用毒品，或介绍、煽动、诱骗他人制造、使用、交易毒品或其他管制药品的内容；</p>
          <p class="text"><span class="span_dot"></span>提供购买、销售毒品、管制药品的渠道或招揽使用毒品或其他管制药品的内容；</p>
          <p class="text"><span class="span_dot"></span>详细展示吸毒后极度亢奋的生理状态、扭曲的表情等或展示容易引发模仿的各类吸毒工具与吸毒方式的内容。</p>
          <p class="text">（4）违反野生动植物保护规定的内容</p>
          <p class="text">平台禁止用户制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>展示猎捕、杀害国家重点保护野生动物或非法盗挖、采集、毁坏野生植物或者破坏其生长环境的内容；</p>
          <p class="text"><span class="span_dot"></span>展示在相关自然保护区域或禁猎（渔）区、禁猎（渔）期内，实施猎捕或者其他妨碍野生动植物生息繁衍活动的内容；</p>
          <p class="text"><span class="span_dot"></span>推广、买卖捕猎野生动物的工具、传授捕猎方法的内容；</p>
          <p class="text"><span class="span_dot"></span>出售、购买、利用、食用国家重点保护野生动植物及其制品的内容；</p>
          <p class="text"><span class="span_dot"></span>违规饲养野生动物、采集国家重点保护野生植物的内容；</p>
          <p class="text"><span class="span_dot"></span>破坏野生动植物的保护设施和保护标志的内容；</p>
          <p class="text"><span class="span_dot"></span>其他违反野生动植物保护规定的内容。</p>
          <p class="text">（5）妨害文物管理规定的内容</p>
          <p class="text">平台禁止用户制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>含有盗掘、破坏古文化遗址、古墓葬，或者宣传、介绍盗掘、破坏古文化遗址、古墓葬的内容；</p>
          <p class="text"><span class="span_dot"></span>售卖盗墓工具、讲述盗墓方式的内容；</p>
          <p class="text"><span class="span_dot"></span>损毁、买卖、走私、倒卖国家保护的珍贵文物的内容；</p>
          <p class="text"><span class="span_dot"></span>其他违反国家文物管理规定的内容。</p>
          <p class="text">（6）违反人民币管理规定的内容</p>
          <p class="text">平台禁止用户制作、发布或传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>展示毁损人民币的内容或行为；</p>
          <p class="text"><span class="span_dot"></span>非法制作、仿制、买卖人民币图样的内容；</p>
          <p class="text"><span class="span_dot"></span>传播或者售卖伪造、变造人民币的工具、方法的内容；</p>
          <p class="text"><span class="span_dot"></span>出售、购买、走私、运输、持有、使用伪造、变造的人民币的内容；</p>
          <p class="text"><span class="span_dot"></span>其他违反人民币管理规定的内容。</p>
        `,
      },
      {
        id: '1-6',
        text: '帮助实施或教唆犯罪',
        content: `
          <p class="text">为竭力防止现实世界中可能发生的伤害事件和危险模仿行为，平台禁止用户利用平台服务组织、加入、帮助、鼓动或宣扬任何会对其他个人或组织、动物带来伤害或财产损失的违法、犯罪活动或有害行为，包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>通过曝光隐私、公开披露他人真实身份信息等方式，帮助、教唆或组织不特定用户对他人发起人身攻击、人肉搜索、线下骚扰等行为；</p>
          <p class="text"><span class="span_dot"></span>发布描述或宣扬虐待、虐杀动物的内容，教唆、诱导、鼓励或帮助实施伤害动物的行为；</p>
          <p class="text"><span class="span_dot"></span>宣言、呼吁或组织不特定用户实施恶意毁坏公私财产，开展黑客攻击，抢夺、偷盗、侵占他人财物的行为；</p>
          <p class="text"><span class="span_dot"></span>帮助或教唆他人从事法律法规禁止的其他违法犯罪行为。</p>
        `,
      },
    ],
  },
  {
    title: '二、时政有害及不实信息',
    id: 2,
    subtitles: [
      {
        id: '2-1',
        text: '时政有害信息',
        content: `
          <p class="text">平台严禁制作、发布、传播含有下列危害国家及社会安全的时政有害信息：</p>
          <p class="text"><span class="span_dot"></span>反对宪法确定的基本原则的；</p>
          <p class="text"><span class="span_dot"></span>危害国家统一、主权和领土完整的；</p>
          <p class="text"><span class="span_dot"></span>泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</p>
          <p class="text"><span class="span_dot"></span>宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
          <p class="text"><span class="span_dot"></span>煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p class="text"><span class="span_dot"></span>破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p class="text"><span class="span_dot"></span>散布谣言，扰乱经济秩序和社会秩序的；</p>
          <p class="text"><span class="span_dot"></span>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
          <p class="text"><span class="span_dot"></span>煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
          <p class="text"><span class="span_dot"></span>歪曲、丑化、亵渎、否定英雄烈士及其事迹、精神；</p>
          <p class="text"><span class="span_dot"></span>以贬损、玷污、焚烧、涂划、践踏、篡改等方式，侮辱、恶搞、歪曲、丑化国旗、国歌、国徽、人民币、军旗、军歌、军徽等具有特殊含义的象征、标志、音效的；</p>
          <p class="text"><span class="span_dot"></span>含有法律法规禁止的其他涉及时政的有害信息。</p>
        `,
      },
      {
        id: '2-2',
        text: '时政不实信息',
        content: `
          <p class="text">平台严禁制作、发布、传播含有损害国家形象及社会秩序的时政不实信息。</p>
          <p class="text"><span class="span_dot"></span>个人或组织为了其特定的政治目的，对党和国家领导人以及具有政治意义的公众人物进行的有意诬陷和诽谤；</p>
          <p class="text"><span class="span_dot"></span>以扰乱政治生态、制造社会混乱等为目的，在某些重大突发事件、灾情等发生后扩散的谣言；</p>
          <p class="text"><span class="span_dot"></span>含有法律法规禁止的其他涉及时政的不实信息；</p>
          <p class="text"><span class="span_dot"></span>针对时政信息的日常管理，平台将根据主管部门的要求对包含时政有害信息的内容和账号进行依法处置。</p>
        `,
      },
    ],
  },
  {
    title: '三、侵犯人身权益',
    id: 3,
    subtitles: [
      {
        id: '3-1',
        text: '自杀、自残',
        content: `
          <p class="text">平台欢迎用户发布心理健康相关的有益探讨和科学研究内容，但是禁止制作、发布或传播任何展示、宣扬、诱导、鼓励、组织或教唆自杀和自残的内容，或者任何泄漏自杀或自残受害者或幸存者身份并攻击他们的负面内容。</p>
          <p class="text">平台禁止制作发布或传播以下自杀、自残相关内容：</p>
          <p class="text">（1）展示或宣扬自杀、自残：</p>
          <p class="text"><span class="span_dot"></span>宣扬或美化自杀、自残的内容；</p>
          <p class="text"><span class="span_dot"></span>宣扬、展示自杀游戏、自杀冒险的内容；</p>
          <p class="text"><span class="span_dot"></span>扬言、演绎自杀、自残类内容；</p>
          <p class="text"><span class="span_dot"></span>展示因自杀、自残造成的血迹、伤疤等的影像内容，且未经模糊处理或充分遮挡；</p>
          <p class="text"><span class="span_dot"></span>展示自杀准备工作或自杀尝试和自杀抢救片段的内容，但未提供充分的背景信息；</p>
          <p class="text"><span class="span_dot"></span>讲述自杀、自残幸存者的自杀、自残过程、经历等，但未进行充分风险提示的内容；</p>
          <p class="text"><span class="span_dot"></span>描绘他人尝试自杀或因自杀而死亡，未进行充分风险提示的内容；</p>
          <p class="text"><span class="span_dot"></span>曝光自杀或自残受害者或幸存者身份，并嘲笑、攻击他们的负面内容；</p>
          <p class="text"><span class="span_dot"></span>其他展示、宣扬或美化自杀、自残行为的内容。</p>
          <p class="text">（2）煽动、诱导或教唆他人自杀、自残：</p>
          <p class="text"><span class="span_dot"></span>教唆、帮助、诱导、授意、怂恿、威胁或指导他人实施自杀或自残的内容；</p>
          <p class="text"><span class="span_dot"></span>分享、传授自杀、自残的信息、策略、方法或说明的内容；</p>
          <p class="text"><span class="span_dot"></span>诱导或者胁迫他人参与具有自杀、自残类性质的挑战、游戏或其他活动；</p>
          <p class="text"><span class="span_dot"></span>相约或号召共同实施自杀、自残的内容；</p>
          <p class="text"><span class="span_dot"></span>请求他人帮助自己或他人进行自杀或自残的内容，包括为集体自杀或自杀游戏寻求伙伴；</p>
          <p class="text"><span class="span_dot"></span>在他人表达自杀意图时，采用激将、起哄等方式怂恿他人立即实施自杀、自残行为；</p>
          <p class="text"><span class="span_dot"></span>提供心理暗示，在心理或者精神上鼓励、促使他人自杀、自残的内容；</p>
          <p class="text"><span class="span_dot"></span>其他容易引发他人模仿或强化他人自杀、自残意图的内容或行为；</p>
          <p class="text">如果用户遇到心理上的困扰，请及时拨打110，寻求警察帮助。</p>
        `,
      },
      {
        id: '3-3',
        text: '侵犯人身自由',
        content: `
          <p class="text">公民的人身自由权利依法受到保护。国家禁止买卖人口、强迫劳动、买卖婚姻、买卖人体器官、非法收养送养未成年人等各类侵犯和威胁公民人身权益和自由的违法犯罪行为。平台严禁用户发布或传播各类展示、宣扬、美化侵犯他人人身自由权利的内容，对于其中可能构成违法犯罪的，平台将积极配合执法及司法机关进行处理。</p>
          <p class="text">平台禁止发布、传播以下可能导致或加剧侵犯他人的人身权益和自由的内容：</p>
          <p class="text"><span class="span_dot"></span>拐卖和贩运妇女、儿童的内容；</p>
          <p class="text"><span class="span_dot"></span>非法买卖、非法收养或送养儿童，寻求潜在买家或卖家的内容；</p>
          <p class="text"><span class="span_dot"></span>招募童工、强迫儿童参与劳动；</p>
          <p class="text"><span class="span_dot"></span>强迫、买卖婚姻，或其他以暴力、胁迫等方式侵犯婚姻自由的内容；</p>
          <p class="text"><span class="span_dot"></span>其他侵犯人身安全和自由或宣扬、美化相关违法犯罪行为的内容；</p>
        `,
      },
      {
        id: '3-4',
        text: '危险行为',
        content: `
          <p class="text">危险行为通常是指在非专业环境中，或没有必要的技能与安全预防措施保障下实施，可能导致行为人自身、他人或不特定公众遭受人身伤亡或财产损失的行为。危险行为不仅会威胁用户自身生命财产安全，还可能会对诱导其他用户产生模仿、学习、传播等不良后果。平台禁止用户发布涉及高危险行为、危险驾驶、危险恶作剧等内容，以及展示危险工具、危险玩具等内容。</p>
          <p class="text">平台禁止发布、传播的危险行为相关内容，包括但不限于：</p>
          <p class="text">（1）高度危险的“实验”或“挑战”行为</p>
          <p class="text"><span class="span_dot"></span>进食有害物质，或以可能严重危害生命或健康的方式进食，如食用、饮用或注射非可食用物品或可能导致疾病或中毒的化学物品；</p>
          <p class="text"><span class="span_dot"></span>可能导致窒息的活动或挑战，如卡住喉咙、溺水或吊颈游戏、吞食不能吃的物品；</p>
          <p class="text"><span class="span_dot"></span>焚烧、冷冻和电击等危险活动，如本身具有严重烧伤、爆炸、冻僵、冻伤或触电等风险的活动；</p>
          <p class="text"><span class="span_dot"></span>违背正常健康习惯的摔倒、穿刺、碰撞、钝器创伤或碾压活动或挑战；</p>
          <p class="text"><span class="span_dot"></span>缺乏必要技术指导或设备保障，或未成年人没有监护人指导下实施的可能具有爆炸、烧伤、烫伤、中毒等人身伤害风险的实验活动；</p>
          <p class="text"><span class="span_dot"></span>非专业人员实施的极限运动挑战，如非专业人员展示翼装飞行、无防护的高空走钢丝等极限运动；</p>
          <p class="text"><span class="span_dot"></span>由未成年人进行的与其年龄、身体和心理发育程度不相符的活动，如未成年人饮酒、吸烟，或以不规范的方式燃放烟花爆竹等危险活动。</p>
          <p class="text">（2）危险或威胁性恶作剧</p>
          <p class="text"><span class="span_dot"></span>通过拳头击打、在食物或饮料中加非可食用物质或者电击等恶作剧手段对他人造成身体伤害的内容；</p>
          <p class="text"><span class="span_dot"></span>即使没有受到真实的身体伤害，但欺骗他人、让他人相信自己处于真实危险之中的恶作剧，如使用“武器”威胁、虚构入室抢劫、虚构绑架、假装报警等；</p>
          <p class="text"><span class="span_dot"></span>使他人陷入被胁迫、担心自己的人身或财产安全即将受到严重损失情境的恶作剧；</p>
          <p class="text"><span class="span_dot"></span>借开玩笑羞辱他人或者捏造事实，令他人名誉受损；</p>
          <p class="text"><span class="span_dot"></span>对未成年人的心理状态和情绪发展造成不良影响的恶作剧，如假装父母等监护人会抛弃孩子、诈死或假装自杀，展示父母等监护人虐待或羞辱孩子等内容。</p>
          <p class="text">（3）其他易造成伤害或引发不当模仿的危险行为或器具</p>
          <p class="text"><span class="span_dot"></span>展示醉酒、吸毒等不应驾驶机动车状态下的驾驶行为，或违规追逐竞驶、违规超车、超载驾驶等危险驾驶内容的；</p>
          <p class="text"><span class="span_dot"></span>展示具有杀伤力的危险玩具，或存在质量与安全隐患的玩具。</p>
        `,
      },
      {
        id: '3-5',
        text: '侵犯隐私与个人信息',
        content: `
          <p class="text">平台高度重视用户隐私及个人信息的保护。平台禁止用户在未经权利人许可且无法律规定的合理理由的情况下发布、曝光或索取他人的个人信息或隐私，包括但不限于个人身份识别信息、财务信息、居住信息、医疗信息、线上或线下的活动信息以及其他不愿为他人所知晓的信息。对于侵犯隐私及个人信息的行为，平台提供举报功能，方便用户进行举报。</p>
          <p class="text">平台禁止未经授权或许可发布、传播、索要或分享以下内容或实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>曝光他人身份证信息、护照信息、驾照信息、未公开的官方记录或文件中的个人信息等证件信息的内容；</p>
          <p class="text"><span class="span_dot"></span>曝光他人财务信息，如未公开的财务记录或报表，支付收款记录，银行账号及密码，第三方支付方式的信息等；</p>
          <p class="text"><span class="span_dot"></span>曝光他人住址、位置信息 ，如能够识别地址的居住信息、住宿信息、地理位置定位等；</p>
          <p class="text"><span class="span_dot"></span>曝光他人身体、疾病相关信息，如他人的医疗记录、生物特征信息或基因遗传信息；</p>
          <p class="text"><span class="span_dot"></span>曝光他人联系方式，如邮箱地址、电话号码等；</p>
          <p class="text"><span class="span_dot"></span>曝光他人网络账号及其密码、网站浏览记录等；</p>
          <p class="text"><span class="span_dot"></span>曝光其他他人的个人信息与隐私内容，如婚姻关系、私人空间、私人活动、身份或职业相关信息，或破坏他人生活安宁；</p>
          <p class="text"><span class="span_dot"></span>买卖他人隐私、个人信息的行为。</p>
        `,
      },
      {
        id: '3-6',
        text: '其他侵犯人身权益的行为',
        content: `
          <p class="text">其他侵犯他人人身权益的行为包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>滥用、冒用他人肖像、姓名、名称，侵犯他人肖像权、姓名权、名称权的行为；</p>
          <p class="text"><span class="span_dot"></span>采用诽谤、诋毁等手段，损害他人名誉、降低他人社会评价或损害公众对他人信赖，降低他人产品或者服务社会评价的行为；</p>
          <p class="text"><span class="span_dot"></span>其他雇佣、组织、教唆或者帮助他人发布、转发侵害他人人身权益的行为。</p>
        `,
      },
    ],
  },
  {
    title: '四、违法与不良内容',
    id: 4,
    subtitles: [
      {
        id: '4-1',
        text: '色情、淫秽内容',
        content: `
          <p class="text">平台禁止用户发布、传播含有色情、淫秽信息，或对色情、淫秽信息资源进行引流等违法违规内容。 色情、淫秽内容包括具体描绘性行为或者露骨宣扬色情的诲淫性的文字、图片等信息。制作、发布、传播、贩卖色情、淫秽内容属于违法行为，并可能构成犯罪。有关人体生理、医学知识的内容不是淫秽信息。</p>
        `,
      },
      {
        id: '4-2',
        text: '仇恨与歧视性言论',
        content: `
          <p class="text">平台致力于打造一个用户互相尊重、和谐共处的社区环境，严禁用户发布、传播任何形式的仇恨和歧视性言论，包括但不限于基于性别、年龄、地域、民族、种族、宗教信仰、国籍、身体状况，或精神缺陷、受害经历、性别认同、经济地位、受教育水平等的歧视和仇恨。</p>
          <p class="text">平台禁止发布、传播以下歧视和仇恨性言论：</p>
          <p class="text"><span class="span_dot"></span>故意贬低特定个人或群体，或宣扬针对特定群体的刻板印象、仇恨等；</p>
          <p class="text"><span class="span_dot"></span>侮辱、贬损他人的职业身份、社会地位、身体特征、健康状况等；</p>
          <p class="text"><span class="span_dot"></span>以特定个人或群体为目标，进行威胁、煽动暴力或以其他方式使特定个人或群体遭受公众歧视或仇恨；</p>
          <p class="text"><span class="span_dot"></span>组织、煽动、引导他人对特定个人和群体实施基于上述差异的诽谤、谩骂、侮辱、歧视、仇恨；</p>
          <p class="text"><span class="span_dot"></span>展示带有歧视和仇恨意识形态的内容。</p>
        `,
      },
      {
        id: '4-3',
        text: '低俗媚俗内容',
        content: `
          <p class="text">平台希望与用户一起共建一个和谐、文明的社区环境，鼓励用户发布文明、健康的创作内容。对于以博眼球、吸引流量为目的，宣扬、展示传播低级趣味，低俗、媚俗，具有性暗示或性挑逗意味的内容平台将采取相应的限制措施。</p>
          <p class="text">平台禁止发布、传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>展示具有性挑逗、性骚扰、性暗示、诱导性联想的行为或有类似效果的言语、物品、画面、音乐、音效等；</p>
          <p class="text"><span class="span_dot"></span>旨在激起和满足性欲的露骨色情语言、动作等内容；</p>
          <p class="text"><span class="span_dot"></span>刻意突出或用很小的遮盖物掩盖人体隐秘部位及衣着过分暴露的内容；</p>
          <p class="text"><span class="span_dot"></span>着重展示臀部、颈部、裸露的腿部等身体某个部位，易引发性幻想或性联想的内容；</p>
          <p class="text"><span class="span_dot"></span>以隐晦、低俗的语言表达使人产生性行为和性器官联想的内容；</p>
          <p class="text"><span class="span_dot"></span>以感官刺激为目的，集中展现与性行为有关的、间接表现或暗示的内容；</p>
          <p class="text"><span class="span_dot"></span>使用粗俗语言、随意侮辱谩骂、展示恶俗行为的内容；</p>
          <p class="text"><span class="span_dot"></span>展示恶俗、低俗、庸俗的民俗婚俗或相关婚闹行为；</p>
          <p class="text"><span class="span_dot"></span>展示带有侵犯个人隐私性质的走光、偷拍等；</p>
          <p class="text"><span class="span_dot"></span>含有刻意展示易产生低俗联想的物品；</p>
          <p class="text"><span class="span_dot"></span>其他宣扬低俗、庸俗、媚俗的内容。</p>
        `,
      },
      {
        id: '4-4',
        text: '血腥暴力内容',
        content: `
          <p class="text">平台反对用户发布、传播展示及美化暴力、血腥场景或者对他人遭受的苦难或屈辱幸灾乐祸的内容。这样的内容可能引发其他用户的心理和生理不适。平台允许为帮助人们提升对特定问题的认知或者媒体出于新闻报道的目的而发布血腥内容，但应进行适当打码处理。</p>
          <p class="text">平台禁止发布、传播以下血腥暴力的内容：</p>
          <p class="text">（1）血腥暴力的人体图像</p>
          <p class="text"><span class="span_dot"></span>展现血肉模糊的人体解剖过程、大面积的血腥、人类尸体现场等内容；</p>
          <p class="text"><span class="span_dot"></span>展示自残、自杀或酷刑、死刑执行过程的血腥画面；</p>
          <p class="text"><span class="span_dot"></span>展示凶暴、残酷、恐怖、极端的犯罪过程及肉体、精神虐待的内容；</p>
          <p class="text"><span class="span_dot"></span>刻意突出断肢、内脏等人体器官；</p>
          <p class="text"><span class="span_dot"></span>展示人体遭非医用异物插入或卡入，并造成严重伤害的画面；</p>
          <p class="text"><span class="span_dot"></span>展示含有肢解、内脏外漏、割喉等伤者、死者画面的内容；</p>
          <p class="text"><span class="span_dot"></span>展示个人或群体因意外或谋杀死亡的内容。</p>
          <p class="text">（2）血腥暴力的动物图像</p>
          <p class="text"><span class="span_dot"></span>主旨展示屠宰、虐杀动物过程的内容；</p>
          <p class="text"><span class="span_dot"></span>突出动物打斗、撕咬或对人进行攻击，造成的血肉模糊场景的内容；</p>
          <p class="text"><span class="span_dot"></span>展示折磨、虐待动物或使用其他非人道方式残害动物造成的血腥场面；</p>
          <p class="text"><span class="span_dot"></span>刻意展示严重动物伤口（如能透过伤口看到露出的脏器或骨骼）或肢解场面。</p>
          <p class="text">（3）事故场景相关的血腥暴力内容</p>
          <p class="text"><span class="span_dot"></span>展示严重的打架斗殴、群体冲突的过程以及暴力现场；</p>
          <p class="text"><span class="span_dot"></span>刻意展示各类血腥画面，如严重交通事故现场、血腥游戏、凶案案发现场等。</p>
        `,
      },
      {
        id: '4-5',
        text: '惊悚恐怖内容',
        content: `
          <p class="text">惊悚恐怖的内容极易造成强烈的感官和精神刺激，使观看者感到不适，亦容易危害到未成年人的身心健康，因此平台不欢迎该类内容。</p>
          <p class="text">平台禁止发布、传播以下有过度惊吓恐怖的画面、字幕、背景音乐及声音效果的内容，包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>恐怖、灵异、诅咒类内容；</p>
          <p class="text"><span class="span_dot"></span>带有恐怖画面的图集；</p>
          <p class="text"><span class="span_dot"></span>灵异事件讲述、恐怖文字描述等；</p>
          <p class="text"><span class="span_dot"></span>恐怖漫画、恐怖动漫或恐怖游戏；</p>
          <p class="text"><span class="span_dot"></span>其他对观看者可能造成强烈感官和精神刺激，从而引发身心惊恐、焦虑、厌恶、恶心等不适感的内容。</p>
        `,
      },
      {
        id: '4-6',
        text: '残忍麻木内容',
        content: `
          <p class="text">平台坚持和弘扬社会主义核心价值观，鼓励积极向上的生活方式。平台谴责漠视生命、嘲笑他人苦难、以他人所受屈辱为乐的行为并拒绝该类内容的传播。</p>
          <p class="text">平台不欢迎用户发布、传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>描述他人不幸经历，并嘲笑对方所经历的痛苦与屈辱。不幸经历包括但不限于：严重或致命的疾病、残疾等身体创伤、自然灾难或重大事故、肢体暴力与精神侮辱；</p>
          <p class="text"><span class="span_dot"></span>以幸灾乐祸的态度描述动物所遭受的痛苦情形，以此为乐或为施害方叫好。动物遭受的情形包括但不限于：死亡、严重的身体创伤、被施加肢体暴力或其他伤害；</p>
          <p class="text"><span class="span_dot"></span>过度展现他人生理、精神痛苦，且对普通观看者可能造成强烈感官和精神刺激的内容；</p>
          <p class="text"><span class="span_dot"></span>其他违背道德底线，缺乏尊重他人、敬畏生命精神的的残忍与麻木的内容；</p>
          <p class="text">鉴于每个用户对上述不良内容的敏感程度和接受能力不同，尤其是未成年人用户的接受程度更低。</p>
        `,
      },
    ],
  },
  {
    id: 5,
    title: '五、不实信息',
    subtitles: [
      {
        id: '5-1',
        text: '不实信息',
        content: `
          <p class="text">为避免对其他用户造成伤害、误导，扰乱正常的社会生活秩序，平台禁止用户利用平台服务编造、发布、传播谣言以及其他不实信息，具体包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>在社会热点事件期间或针对公众所关心的事件、人物，编造或者传播未经证实的信息，以达到吸引眼球、博取流量或对特定的个人或群体造成伤害的内容或行为；</p>
          <p class="text"><span class="span_dot"></span>出于非法目的，传播旨在破坏党和政府形象、危害国家安全和政权稳定的虚假信息，如捏造政治内幕、政治事件隐情、重要政策调整等信息；</p>
          <p class="text"><span class="span_dot"></span>旨在引发公众恐慌、故意扰乱社会经济发展秩序等的不实内容，如虚构恐怖事件或者危害公众安全事件，预言重大自然灾害，或者夸大灾害事件严重程度；</p>
          <p class="text"><span class="span_dot"></span>可能直接造成紧迫的暴力行为或人身伤害的错误信息和无法证实的传言；</p>
          <p class="text"><span class="span_dot"></span>可能对正常社会秩序和安全造成紧迫伤害或威胁的不实信息或传言；</p>
          <p class="text"><span class="span_dot"></span>利用基于深度学习、虚拟现实等的新技术、新应用制作、发布、传播的虚假信息；</p>
        `,
      },
    ],
  },
  {
    id: 6,
    title: '六、违反公序良俗',
    subtitles: [
      {
        id: '6-1',
        text: '违背社会公德的内容',
        content: `
          <p class="text">（1）有悖公德良善的内容</p>
          <p class="text"><span class="span_dot"></span>以恶搞方式描绘重大自然灾害、意外事故、恐怖事件、战争等灾难场面的内容；</p>
          <p class="text"><span class="span_dot"></span>以肯定、赞许的基调或引入模仿的方式表现打架斗殴、羞辱他人、污言秽语的内容；</p>
          <p class="text"><span class="span_dot"></span>以虚构慈善捐赠事实、编造和渲染他人悲惨身世等方式，传播虚假慈善、伪正能量的内容；</p>
          <p class="text"><span class="span_dot"></span>侮辱逝者，或以商业、娱乐等不当方式侮辱、破坏、踩踏陵墓设施或环境的内容；</p>
          <p class="text"><span class="span_dot"></span>未经他人允许，或无视他人意愿，恶意搭讪、恶搞、骚扰他人的行为；</p>
          <p class="text"><span class="span_dot"></span>其他有悖于社会公德与善良风俗的内容。</p>
          <p class="text">（2）传播不文明行为的内容</p>
          <p class="text">不文明行为违背社会道德规范，展示、美化、宣扬不文明行为的内容，不仅容易造成用户的强烈反感，更容易为他人尤其是未成年人做出错误示范。该类内容有悖平台一直以来所提倡的积极健康的生活观念，因此平台谴责宣扬不文明现象的行为并拒绝该类内容的传播，包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>故意从事公共场所明令禁止的行为，如在旅游景点乱涂乱画、随意刻字等；</p>
          <p class="text"><span class="span_dot"></span>刻意破坏公共卫生，如随地吐痰、乱扔废弃物等；</p>
          <p class="text"><span class="span_dot"></span>刻意扰乱公共场所秩序，如逃票、翻越闸机等。</p>
        `,
      },
      {
        id: '6-2',
        text: '传播不良价值导向的内容',
        content: `
          <p class="text">平台倡导平等友善、多元丰富、真实美好、积极健康的社区环境，尊重知识，鼓励用户进行创作和表达，但同时反对用户为了获取流量和热度，发布违背公序良俗、传递错误价值导向、对生命缺乏敬畏、对人缺乏尊重的消极、负面内容。</p>
          <p class="text">平台不欢迎用户发布、传播以下内容：</p>
          <p class="text">（1）展示消极颓废的人生观、世界观和价值观的内容</p>
          <p class="text"><span class="span_dot"></span>宣扬流量至上、奢靡享乐、炫富拜金等不良价值观，展示违背伦理道德的糜烂生活的内容；</p>
          <p class="text"><span class="span_dot"></span>展示超额消费、过度消费，打造富豪人设的不良导向的内容；</p>
          <p class="text"><span class="span_dot"></span>宣扬违背正确婚恋观和家庭伦理道德的内容；</p>
          <p class="text"><span class="span_dot"></span>宣扬天价彩礼、大操大办、婚嫁陋习等不良婚恋习俗；</p>
          <p class="text"><span class="span_dot"></span>展现“饭圈”乱象和不良粉丝文化，鼓吹炒作流量至上、畸形审美、狂热追星、粉丝非理性发声和应援、明星绯闻丑闻的内容；</p>
          <p class="text"><span class="span_dot"></span>不正当展示自身优越条件、社会地位，存在对非富群体进行歧视、嘲讽或攻击等行为的内容；</p>
          <p class="text"><span class="span_dot"></span>宣传和宣扬厌世情绪、丧文化以及消极负面亚文化，渲染悲观生活状态内容的内容。</p>
          <p class="text">（2）宣扬低俗庸俗，娱乐化倾向严重的内容</p>
          <p class="text"><span class="span_dot"></span>宣扬传播以丑示美、化丑为美的畸形“流量崇拜”行为或细致展示恶俗行为、审丑文化的；</p>
          <p class="text"><span class="span_dot"></span>恶意制造舆论热点，揭秘炒作不良绯闻、丑闻、劣迹历史等行为的内容；</p>
          <p class="text"><span class="span_dot"></span>有伤社会风化、易引起不良联想及模仿的内容，包括但不限于衣着暴露不雅、言行低俗等；</p>
          <p class="text"><span class="span_dot"></span>展示良莠不齐、哗众取宠、价值观紊乱等行为；</p>
          <p class="text"><span class="span_dot"></span>网络恶搞、调侃等迎合低级趣味的内容。</p>
          <p class="text">（3）不当推广、虚假演绎的内容</p>
          <p class="text"><span class="span_dot"></span>不当推广炒作的内容，包括但不限于以卖惨、出轨、家暴、炫富、引战、恶搞、虐待等违反公序良俗的内容或通过刻意制造夸张、猎奇噱头等方式进行炒作；</p>
          <p class="text"><span class="span_dot"></span>出于获取经济、社会或其他利益的目的，采用欺骗等手段营造人设、进行虚假剧情演绎，骗取关注和流量的行为或内容；</p>
          <p class="text"><span class="span_dot"></span>营造虚假人设及遭遇的卖惨带货、演戏炒作等违背公序良俗层面的内容或行为；</p>
          <p class="text"><span class="span_dot"></span>含有法律、行政法规或相关主管部门禁止的其他内容，或者可能危害社会公德、破坏网络生态的内容的。</p>
        `,
      },
      {
        id: '6-3',
        text: '严重伤害民族情感的内容',
        content: `
          <p class="text">平台倡导用户秉承尊重历史、尊重文化传统的态度和精神进行内容创作，共同传承中华民族优秀历史文化与传统。</p>
          <p class="text">平台禁止发布、传播以下内容：</p>
          <p class="text"><span class="span_dot"></span>宣扬、美化法西斯主义、极端民族主义、种族主义的内容；</p>
          <p class="text"><span class="span_dot"></span>美化或歪曲解读历史上侵略战争和殖民史的内容；</p>
          <p class="text"><span class="span_dot"></span>歪曲贬低民族优秀文化传统的内容；</p>
          <p class="text"><span class="span_dot"></span>其他伤害民族情感的内容。</p>
        `,
      },
    ],
  },
  {
    id: 7,
    title: '七 、违反知识产权保护',
    subtitles: [
      {
        id: '7-1',
        text: '知识产权保护',
        content: `
          <p class="text">平台高度重视知识产权保护，尊重原创，鼓励用户创作有价值的作品。为此，平台一方面保障用户对自己作品享有的知识产权，另一方面也严格要求用户不应上传侵犯他人知识产权的作品。如果用户在平台上传侵犯他人知识产权的内容，平台将根据相关法律法规要求进行处理。</p>
          <p class="text">平台禁止发布、传播以下侵犯他人知识产权的内容或实施以下侵犯他人知识产权的行为：</p>
          <p class="text"><span class="span_dot"></span>未经许可，非法使用他人拥有知识产权的内容，包括但不限于文字作品、影音作品、商标等；</p>
          <p class="text"><span class="span_dot"></span>冒用他人名义发布他人拥有知识产权的内容；</p>
          <p class="text"><span class="span_dot"></span>其他任何侵犯第三方知识产权等合法权益的行为；</p>
          <p class="text">用户在平台上发布的所有原创内容和信息都归用户所有。</p>
        `,
      },
    ]
  },
  {
    id: 8,
    title: '八、侵害未成年人权益',
    subtitles: [
      {
        id: '8-1',
        text: '性侵害未成年人',
        content: `
          <p class="text">对未成年人性侵害通常是指行为人违反未成年人意志或利用行为人优势地位，在违反法律或社会道德禁忌的情况下实施的实现性目的相关行为。平台禁止以任何形式对未成年人进行性引诱、性侵害的行为及相关内容。</p>
          <p class="text">（1）性引诱未成年人</p>
          <p class="text">性引诱行为通常是指成年人与未成年人建立情感关系以获取未成年人的信任，以便进行性接触、性侵害等行为。这些行为包括：“讨好”未成年人、建立平台上或平台外的联系、请求提供未成年人的隐私图像（如裸照）或给未成年人发送色情信息等。</p>
          <p class="text">平台禁止发布、传播以下内容或借助平台服务实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>借助网络服务，不当接触未成年人以便对未成年人实施性侵害的行为；</p>
          <p class="text"><span class="span_dot"></span>通过勒索、胁迫、诱骗等手段获取未成年人的裸露图片或与未成年人发生性接触；</p>
          <p class="text"><span class="span_dot"></span>假借“招募童星”、“生理检查”、“恋爱交友”等幌子，骗取未成年人信任，进而索取未成年人裸照或与未成年人进行裸聊或线下性接触的行为；</p>
          <p class="text"><span class="span_dot"></span>向未成年人发送、展示或提供色情图像或信息；</p>
          <p class="text"><span class="span_dot"></span>描述、宣传、正常化或美化性引诱行为的内容；</p>
          <p class="text"><span class="span_dot"></span>其他以对未成年人实施性侵害为目的的，通过网络接触儿童的行为。</p>
          <p class="text">以上行为可能构成猥亵或强奸犯罪，平台一经发现，将按照法律规定转交执法及司法机关进行处理。</p>
          <p class="text">（2）宣扬、美化未成年人性侵害的内容</p>
          <p class="text">平台禁止发布、传播以下内容或借助平台服务实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>描述、宣传、正常化或美化恋童癖或性侵未成年人的内容；</p>
          <p class="text"><span class="span_dot"></span>分享、转发、买卖或指引用户在平台之外获取或散布儿童性侵害制品的内容；</p>
          <p class="text"><span class="span_dot"></span>污名化未成年人性侵害受害人、对受害人进行指责、谩骂或曝光受害人隐私的内容；</p>
          <p class="text"><span class="span_dot"></span>透过第三方转载或重现侵害场景或司法审判中的内容,对未成年受害者造成二次伤害的内容；</p>
          <p class="text"><span class="span_dot"></span>煽动、教唆对未成年人实施性侵害或为此提供帮助的行为。</p>
        `,
      },
      {
        id: '8-2',
        text: '未成年人性侵害相关内容',
        content: `
          <p class="text">未成年人性侵害相关内容主要是指表现未成年人的裸露和性行为的内容，包括公开生殖器官的内容，或涉及未成年人模仿、暗示或展示性行为的内容。平台禁止任何形式的描绘未成年人裸露或性行为的内容，包括以数字技术制作或合成的内容，以及未成年人相关的软色情内容。</p>
          <p class="text">平台禁止发布、上传、分享：</p>
          <p class="text"><span class="span_dot"></span>以明确或隐晦方式描绘未成年人性行为的内容；</p>
          <p class="text"><span class="span_dot"></span>描绘暴露未成年人的生殖器等敏感部位的内容；</p>
          <p class="text"><span class="span_dot"></span>包含描述或描绘未成年人露骨色情语言、文字的内容；</p>
          <p class="text"><span class="span_dot"></span>描绘涉及未成年人性暗示、性挑逗的内容；</p>
          <p class="text"><span class="span_dot"></span>描绘未成年人进行露骨的色情表演的内容；</p>
          <p class="text"><span class="span_dot"></span>描述未成年人穿著与身处环境无关的暴露衣著的内容；</p>
          <p class="text"><span class="span_dot"></span>用于掩饰或暗示未成年人裸露或性行为的性化留言、表情符号、文字或其他图像；</p>
          <p class="text"><span class="span_dot"></span>此外，平台禁止发布、传播、使用未成年人的性征化软色情内容，包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>着重突出未成年人性征的，含有性暗示、性挑逗意味的内容；</p>
          <p class="text"><span class="span_dot"></span>以未成年人为主体，印着带有性暗示、性挑逗文字的图片等内容。</p>
        `,
      },
      {
        id: '8-3',
        text: '不当利用未成年人牟取利益',
        content: `
          <p class="text">不当利用未成年人牟取利益主要是指违反《中华人民共和国未成年人保护法》《中华人民共和国广告法》《中华人民共和国劳动法》等法律规定，侵犯未成年人合法权益、利用未成年人谋取经济利益的行为。</p>
          <p class="text"><span class="span_dot"></span>平台禁止发布、传播以下内容或借助平台服务实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>利用儿童摆出不雅姿势、做性暗示动作等吸引流量、带货牟利；</p>
          <p class="text"><span class="span_dot"></span>利用未成年人形象进行色情等不当内容等引流、导流；</p>
          <p class="text"><span class="span_dot"></span>利用未成年人制作侵害未成年人健康、不适合未成年人观看的不良节目；</p>
          <p class="text"><span class="span_dot"></span>其他侵害或者忽视未成年人权益、利用未成年人进行牟利的行为。</p>
        `,
      },
      {
        id: '8-4',
        text: '危害或影响未成年人身心健康的内容',
        content: `
          <p class="text">为了保护未成年人的合法权益、避免引发或诱导其他未成年人进行不当模仿，除正当宣教目的之外，平台禁止用户发布、传播描绘或宣扬可能危害或影响未成年人身心健康的不良行为或内容。此处所称不良行为或内容通常是指未成年人实施的不利于其健康成长，甚至是构成违法犯罪的行为。</p>
          <p class="text">平台禁止发布、传播描绘、展示或宣扬未成年人实施的以下不良行为的内容：</p>
          <p class="text"><span class="span_dot"></span>未成年人吸烟、饮酒、旷课、逃学、沉迷网络，参与赌博、封建迷信及邪教活动等行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人与社会上具有不良习性的人交往，组织或者参加实施不良行为的团伙、进入法律法规规定未成年人不宜进入的场所等行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人阅览、观看、收听或讨论宣扬淫秽、色情、暴力、恐怖、极端等内容的读物、音像制品或者网络信息等行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人结伙斗殴、寻衅滋事、赌博、实施伤害他人人身、财产等违法犯罪行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人非法携带枪支、弹药或者弩、匕首等管制器具的行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人参与淫秽活动，传播、持有淫秽物等行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人婚孕等有害未成年人身心健康的违法及不当行为；</p>
          <p class="text"><span class="span_dot"></span>未成年人参与或实施其他不利于未成年人身心健康或严重危害社会的行为。</p>
          <p class="text">此外，平台禁止发布任何组织、教唆、指导、胁迫、引诱、欺骗或帮助未成年人实施不良行为的内容。</p>
        `,
      },
      {
        id: '8-5',
        text: '针对未成年人的其他违法犯罪活动',
        content: `
          <p class="text">平台严禁用户利用平台服务实施针对未成年人的违法犯罪行为，或发布、传播相关内容。</p>
          <p class="text">平台禁止发布、传播如下针对未成年人的违法犯罪行为的相关内容：</p>
          <p class="text"><span class="span_dot"></span>殴打、虐待未成年人，对未成年人实施体罚、家庭暴力、校园暴力等暴力行为；</p>
          <p class="text"><span class="span_dot"></span>绑架、拐卖未成年人等严重侵犯未成年人人身安全；</p>
          <p class="text"><span class="span_dot"></span>对未成年人实施网络暴力或侵犯未成年人的名誉、肖像、隐私等人格权益；</p>
          <p class="text"><span class="span_dot"></span>对未成年人实施诈骗、敲诈勒索、盗窃等侵害未成年人财产权益；</p>
          <p class="text"><span class="span_dot"></span>利用未成年人进行色情、暴力等非法引流；</p>
          <p class="text"><span class="span_dot"></span>其他针对未成年人的违法犯罪内容或行为。</p>
        `,
      },
      {
        id: '8-6',
        text: '对未成年人的其他保护',
        content: `
          <p class="text">除了上述严禁发布的内容外，用户在平台上发布含有未成年人的内容或可能影响未成年人身心健康的内容时，必须做到以下几点：</p>
          <p class="text">首先，作品中含有未成年人的，必须尊重未成年人的隐私权、做好未成年人的隐私保护，拍摄他人的未成年人子女时，要先征得未成年人监护人的同意，确保未成年人是自愿参与拍摄，并做好隐私设置；</p>
          <p class="text">其次，拍摄过程中保证未成年人的人身安全，不能让未成年人从事危险行为，保障未成年人的正常休息、学习和娱乐；不能让未成年人从事与其年龄和心智不符的、有碍未成年人身心健康的表演或行为。</p>
        `,
      },
    ]
  },
  {
    id: 9,
    title: '九、虚假与不诚信行为',
    subtitles: [
      {
        id: '9-1',
        text: '作弊行为与垃圾信息',
        content: `
          <p class="text">平台禁止用户实施作弊行为或发布意图欺骗、误导用户来提高浏览量的垃圾内容。这类内容不仅会降低用户体验、阻碍用户的真实互动，还会威胁到平台服务的安全性、稳定性和可用性。平台致力于阻止个别用户为谋取商业利益，以违规的方式不正当地提高浏览量或批量传播内容的行为。</p>
          <p class="text">平台禁止制作、发布或传播以下内容或借助平台服务实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>未经平台允许，采用自动化手段或明显异于常人的、远高于正常用户频率地创建账号、群聊，发布图文内容或评论、私信，或进行关注、点赞、抓取数据等违规行为；</p>
          <p class="text"><span class="span_dot"></span>大量发布、重复上传无特定目标文本、评论、私信或群聊内容，且存在下列一项或多项行为：</p>
          <p class="text"><span class="span_dot"></span>向观看者承诺他们会看到某些内容，但却将观看者引导至其他网站；</p>
          <p class="text"><span class="span_dot"></span>向观看者承诺他们可以快速赚钱，借此在偶藏之外的平台上获取点击次数或流量；</p>
          <p class="text"><span class="span_dot"></span>将观众引导至传播恶意软件、企图违规收集个人信息的网站或其他会产生负面影响的网站；</p>
          <p class="text"><span class="span_dot"></span>以前述目的运营多个作弊专用的偶藏账号的行为；</p>
          <p class="text">通过作弊行为或发布垃圾信息影响平台运营、破坏用户体验的，平台将通过技术手段进行识别及判断，并有权基于上述判断进行必要处置。</p>
        `,
      },
      {
        id: '9-2',
        text: '不当营销与虚假宣传',
        content: `
          <p class="text">为维持良好社区秩序，避免可能的人身伤害和财产损失，平台反对用户通过发送垃圾信息、夸大误导、虚假宣传等方式进行不当营销，禁止用户发布违反法律法规的服务与交易的内容。</p>
          <p class="text">平台禁止发布、传播以下内容或借助平台服务实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>对某一产品或服务的性能、功能、质量、销售状况、用户评价等做虚假或者引人误解的商业宣传，欺骗、误导消费者。如对商品的安全性能进行没有合理依据的断言或保证，将科学上未定论的观点当作定论用于商品宣传等；</p>
          <p class="text"><span class="span_dot"></span>售卖、交易、植入违法违规或平台禁止的商品或服务，包括但不限于：销售假冒伪劣产品、烟草、枪械武器、毒品、两性用品等，进行诈骗、传销宣传；</p>
          <p class="text"><span class="span_dot"></span>不具有相关资质证照，且/或未经平台认证的用户发布涉及医疗、金融等专业服务的相关内容；</p>
          <p class="text"><span class="span_dot"></span>采用标题党、炒作负面话题、引战等手段进行不当营销；</p>
          <p class="text"><span class="span_dot"></span>其他不当营销的行为。</p>
        `,
      },
      {
        id: '9-3',
        text: '恶意导流',
        content: `
          <p class="text">恶意导流通常是指使用特定内容，如低俗或者其他吸引眼球的信息，诱导用户访问特定页面或完成转发、关注等操作，这些特定页面可能包含有色情、赌博、盗版文学、制假售假等可非法牟利的信息。平台禁止用户通过欺骗、诱导等方式将其他用户引导至可能危及用户人身权益及财产安全的第三方平台。</p>
          <p class="text">平台禁止发布、传播符合以下任一情形的外部链接、二维码等可识别标志：</p>
          <p class="text"><span class="span_dot"></span>可能安装恶意软件的网站或应用的链接；</p>
          <p class="text"><span class="span_dot"></span>骗取用户登录凭据、财务信息等信息的网站或应用的链接；</p>
          <p class="text"><span class="span_dot"></span>为恐怖组织、极端主义组织等危险人物或组织筹集资金或招募人员的网站或应用的链接；</p>
          <p class="text"><span class="span_dot"></span>包含淫秽色情内容的网站或应用的链接；</p>
          <p class="text"><span class="span_dot"></span>销售法律法规禁止交易的物品之网站或应用的链接；</p>
          <p class="text"><span class="span_dot"></span>未经平台许可发布带有商业推广性质的活动链接、二维码，或交易虚拟账号、诱导用户点击等链接；</p>
          <p class="text"><span class="span_dot"></span>发布其他不利于平台良好内容生态的导流信息，如第三方平台账号或带有第三方平台水印、logo 等内容。</p>
        `,
      },
      {
        id: '9-4',
        text: '欺骗行为',
        content: `
          <p class="text">平台严厉打击各类电信诈骗行为、骗取虚假互动或恶意制造流量的行为。平台禁止用户利用平台服务，通过欺骗性手段骗取其他用户信任或将其他用户引导至其他站外平台进行诈骗；禁止用户通过各类欺骗性的行为或手段骗取互动和流量。平台会积极配合执法及司法机关的行动，打击平台上的诈骗行为。</p>
          <p class="text">（1）诈骗</p>
          <p class="text">平台禁止发布、传播以下内容或实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>通过营造虚假人设、进行虚假演绎骗取流量、关注或金钱的；</p>
          <p class="text"><span class="span_dot"></span>通过虚假宣传、夸大效果或收益、刻意隐瞒真实信息等方式进行宣传或引流的；</p>
          <p class="text"><span class="span_dot"></span>打着虚假交友、非法募捐、提供工作、网络兼职、代理加盟、教授相关知识等名义或以其他变相方式骗取他人财物的；</p>
          <p class="text"><span class="span_dot"></span>描述或宣传网络诈骗、传销等违法违规内容的；</p>
          <p class="text"><span class="span_dot"></span>描述或宣传保证高报酬、固定赔率博彩或任何其他类型欺诈投资、理财计划的；</p>
          <p class="text"><span class="span_dot"></span>展示提供现金礼品、“快速致富”方法或传销项目的内容；</p>
          <p class="text"><span class="span_dot"></span>采用欺骗、夸大收益等方式将用户诱导至平台外的非法“理财”网站、群聊、应用等进行诈骗的；</p>
          <p class="text"><span class="span_dot"></span>通过仿冒他人或特定品牌方等方式，骗取关注、打赏的内容；</p>
          <p class="text"><span class="span_dot"></span>做出夸张的承诺，例如某种神奇疗法可以治愈癌症等疾病的；</p>
          <p class="text"><span class="span_dot"></span>其他通过虚构事实、隐瞒真相等方式骗取他人财物的内容或行为。</p>
          <p class="text">（2）虚假互动与恶意制造流量</p>
          <p class="text">平台禁止实施骗取互动或恶意制造流量的行为，包括但不限于：</p>
          <p class="text"><span class="span_dot"></span>要求或声称用户必须与某些内容互动（例如点赞、分享），才能查看承诺提供的其他内容或与之互动；</p>
          <p class="text"><span class="span_dot"></span>以欺骗手段吸引用户点赞、分享、关注、点击或使用应用或网站；</p>
          <p class="text"><span class="span_dot"></span>分享如何以非正常手段增加点击、点赞、关注者、分享或评论的说明；</p>
          <p class="text"><span class="span_dot"></span>控制大量账户人为或者仿冒人为刷流量，如点赞数，投票，评论等；</p>
          <p class="text"><span class="span_dot"></span>其他骗取虚假互动与恶意制造流量的行为或内容。</p>
        `,
      },
    ]
  },
  {
    id: 10,
    title: '十、危害平台秩序与安全',
    subtitles: [
      {
        id: '10-1',
        text: '平台账号管理规则',
        content: `
          <p class="text">用户账号是用户在平台上的身份，是以用户身份使用平台服务的凭证。用户有权选择合法的字符组合作为自己的账号，并自行设置符合安全要求的密码。用户在平台注册、使用的名称、头像、签名、认证信息等账号信息应当符合法律法规的规定、遵循公序良俗。平台依照法律法规的规定制定账号管理规则，对用户的账号及相关行为进行管理。</p>
          <p class="text">（1）用户账号信息规则</p>
          <p class="text">平台要求用户使用个人真实信息进行注册，机构用户的账号信息应当与机构自身的名称、标识等相符合，与机构性质、经营范围和所属行业类型相匹配。用户在注册、变更名称、头像、签名、认证信息等账号信息时，在遵守相关法律法规要求的同时，不得使用或含有以下内容或实施以下行为：</p>
          <p class="text"><span class="span_dot"></span>假冒、仿冒、捏造政党、党政军机关、企事业单位、人民团体和社会组织以及国家（地区）、国际组织的名称、标识；</p>
          <p class="text"><span class="span_dot"></span>假冒、仿冒、捏造新闻网站、报刊社、广播电视机构、通讯社等新闻媒体的名称、标识等，或者擅自使用“新闻”、“报道”等具有新闻属性的名称、标识等；</p>
          <p class="text"><span class="span_dot"></span>假冒、仿冒、恶意关联国家行政区域、机构所在地、标志性建筑物等重要空间的地理名称、标识等；</p>
          <p class="text"><span class="span_dot"></span>假冒、仿冒、捏造其他自然人的姓名、肖像、身份信息等；</p>
          <p class="text"><span class="span_dot"></span>以损害社会公共利益、损害他人权益或者谋取不正当利益等为目的，在账号信息中故意夹带二维码、网址、邮箱、联系方式等，或者使用同音、谐音、相近的文字、数字、符号和字母等；</p>
          <p class="text"><span class="span_dot"></span>使用同音、谐音、相近文字，拼音、数字、符号、字母和无意义文字等侵犯他人合法权益、谋取非法利益或者损害公共利益；</p>
          <p class="text"><span class="span_dot"></span>含有名不副实、夸大其词等可能使公众受骗或者产生误解的内容；</p>
          <p class="text"><span class="span_dot"></span>含有法律、行政法规和国家有关规定禁止的其他内容。</p>
          <p class="text">（2）账号不诚信行为</p>
          <p class="text">平台禁止用户实施以下扰乱平台正常管理秩序、侵犯用户权益的账号不诚信行为：</p>
          <p class="text"><span class="span_dot"></span>恶意注册“偶藏”账号，包括但不限于频繁、批量注册账号；</p>
          <p class="text"><span class="span_dot"></span>未经他人同意，使用他人的姓名、肖像，以他人名义创设偶藏账号，或复制平台上另一个偶藏用户的头像、个人资料、作品，使得其他用户易产生误解、混淆、无法辨别账号的真实性；</p>
          <p class="text"><span class="span_dot"></span>未经平台同意，赠与、借用、出租、转让、售卖或以其他方式许可他人使用本人的账号；</p>
          <p class="text"><span class="span_dot"></span>被禁止使用平台服务的组织或个人采用欺骗、隐瞒真实身份等方式注册平台账号；</p>
          <p class="text"><span class="span_dot"></span>被依法封禁账号后，以相同或者相似的账号信息重新注册以及跨平台重新注册；</p>
          <p class="text"><span class="span_dot"></span>其他违反法律法规及平台秩序的账号不诚信行为。</p>
        `
      },
      {
        id: '10-2',
        text: '平台权益与秩序',
        content: `
          <p class="text">平台禁止任何恶意损害平台权益、扰乱平台正常运营秩序的内容或行为：</p>
          <p class="text"><span class="span_dot"></span>未经平台授权擅自使用偶藏商标、标志或其他知识产权的；</p>
          <p class="text"><span class="span_dot"></span>未经平台认证假冒、仿冒偶藏官方账号或偶藏工作人员的；</p>
          <p class="text"><span class="span_dot"></span>未经平台授权擅自使用偶藏名义宣传推广账号或其他营销活动的；</p>
          <p class="text"><span class="span_dot"></span>组织、煽动、引导不特定多数用户在平台或在平台之外以某种方式进行恶意投诉举报的；</p>
          <p class="text"><span class="span_dot"></span>其他恶意损害平台权益、扰乱平台正常运营秩序的内容或行为。</p>
          <p class="text">另外，为了保护平台良好的生态环境和用户体验，平台鼓励用户避免发布画质模糊、内容混乱、观感体验差的内容。</p>
        `
      },
      {
        id: '10-3',
        text: '危害平台网络安全',
        content: `
          <p class="text">平台禁止任何有损平台服务安全性与可靠性的内容或行为：</p>
          <p class="text"><span class="span_dot"></span>入侵偶藏网站、应用程序或相关网络，或绕过包括但不限于限制用户存取权在内的平台功能设计或防护措施；</p>
          <p class="text"><span class="span_dot"></span>散布含有病毒、特洛伊木马、电脑蠕虫、逻辑炸弹或其他恶意或有害内容的信息；</p>
          <p class="text"><span class="span_dot"></span>对平台进行修改、改编、翻译、反向工程、反向汇编、反向编译或创造任何基于平台的衍生产品（包括任何程序、网页、表格或文件），或尝试复现平台中内嵌的任何源代码、算法、方法或技术；</p>
          <p class="text"><span class="span_dot"></span>使用自动脚本从平台批量收集信息、爬取数据；</p>
          <p class="text"><span class="span_dot"></span>其他侵犯及威胁平台网络安全的内容或行为。</p>
        `
      },
    ]
  },
  {
    id: 11,
    title: '十一 、附则',
    subtitles: [
      {
        id: '11-1',
        text: '附则',
        content: `
          <p class="text">本公约的订立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。如本公约的部分条款因任何原因无效，则该等条款应在不违反法律的前提下按照尽可能接近条文目的的原则进行重新解释和适用，且本公约的其它条款仍应具有完整效力。</p>
          <p class="text">为给用户提供更好的服务或因国家法律法规、政策调整、技术条件、产品功能等变化需要，我们会适时对本公约进行修订，修订内容构成本公约的组成部分。</p>
          <p class="text">本公约中的标题仅为方便阅读而设，并不影响本公约中任何规定的含义或解释。</p>
          <p class="text">本公约自2025年01月6日生效。</p>
        `
      }
    ]
  }
]

export const rightIcon = `<svg t="1736306953126" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3633" width="10" height="10"><path d="M644.896 514.656c-4.647-3.465-7.707-5.211-10.102-7.614-111.882-111.777-223.68-223.642-335.584-335.395-11.498-11.482-17.601-24.432-14.060-40.893 6.549-30.444 42.724-43.056 66.702-23.206 2.638 2.179 5.006 4.691 7.43 7.116 122.147 122.131 244.29 244.268 366.434 366.411 22.218 22.218 22.236 44.944 0.033 67.143-122.749 122.755-245.548 245.451-368.185 368.316-12.166 12.191-26.062 17.589-42.734 13.327-28.957-7.395-40.247-42.511-21.462-65.828 2.323-2.881 5.046-5.457 7.668-8.083 111.214-111.228 222.435-222.459 333.706-333.634 2.407-2.404 5.469-4.166 10.148-7.66z" fill="rgba(255,255,255,0.5)" p-id="3634"></path></svg>`
