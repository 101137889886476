<template>
  <div id="home">
    <header class="hd">
      <div class="container">
        <div class="logo">
          <h1 class="logo__name">偶藏</h1>
          <img class="logo__img" src="./assets/logo.png" />
        </div>
        <nav class="nav">
          <ul class="nav__list">
            <li class="nav__item">
              <a class="nav__item__link" href="#app">APP</a>
            </li>
            <li class="nav__item">
              <a class="nav__item__link" href="#features">功能介绍</a>
            </li>
            <li class="nav__item">
              <a class="nav__item__link" href="#contacts">联系我们</a>
            </li>
            <li
              class="nav__item nav__item1"
              v-on:mouseenter="onMouseenter"
              v-on:mouseleave="onMouseleave"
            >
              <a class="nav__item__link" href="javascript:;">自律公约</a>
              <div class="bd_nav">
                <div class="bd_items">
                  <span
                    class="bd_item"
                    :class="{ bd_item_active: current == item.id }"
                    v-for="item in tabs"
                    :key="item.id"
                    @click="setCurrent(item.id)"
                    >{{ item.name }}</span
                  >
                </div>
                <div class="bd_con">
                  <div
                    class="bd_con_inner"
                    v-if="current == 1"
                    v-html="GeneralProvisions"
                  ></div>
                  <div
                    class="bd_con_inner"
                    v-if="current == 2"
                    v-html="PlatformAdvocacy"
                  ></div>
                  <div style="padding-bottom: 50px;" v-if="current == 3">
                    <div
                      class="bd_con_items"
                      v-for="item in PlatformProhibition"
                      :key="item.id"
                    >
                      <div class="bd_con_title">{{ item.title }}</div>
                      <div v-for="item1 in item.subtitles" :key="item1.title">
                        <div class="bd_con_subtitle" @click="currentContent.includes(item1.id) ? currentContent = currentContent.filter(e => e != item1.id) : currentContent.push(item1.id)">
                          <span>{{ item1.text }}</span>
                          <div class="icon" :class="{'icon_active': currentContent.includes(item1.id)}" v-html="rightIcon"></div>
                        </div>
                        <div
                          class="bd_con_inner bd_con_item_inner"
                          :class="{'bd_con_item_inner_active': currentContent.includes(item1.id)}"
                          v-html="item1.content"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <section class="banner">
      <img class="banner__img" src="./assets/new/banner.jpg" />
      <div class="check_box">
        <div class="inner_box">
          <div class="title">偶藏鉴定认证查询</div>
          <!-- <div class="sub_title">
            请务必确认购买藏品的证书认证编号，查询编号的有效性
          </div> -->
          <div class="check_form">
            <input v-model="value" placeholder="请输入偶藏鉴定认证编码" />
            <router-link
              tag="a"
              target="_blank"
              :to="{ name: 'result', params: { code: value } }"
              v-if="value"
            >
              <div class="btn">
                查<span style="display: inline-block; width: 20px"></span>询
              </div>
            </router-link>
            <div class="btn" v-else>
              查<span style="display: inline-block; width: 20px"></span>询
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="info_box" id="features">
      <div class="info_title">
        <div>BRAND</div>
        <div>INTRODUCATION</div>
        <div class="line"></div>
        <div class="info_name">品牌概况</div>
      </div>
      <div class="info_bottom">
        <div class="info_item">
          <div class="section_title">品牌简介</div>
          <div class="info_inner_item">
            <div class="info_con">
              重庆津南之南网络科技有限公司成立于2016年,坐落于山城重庆。公司专注于体育收藏品和纪念品交易、亲笔签名鉴定，以及体育收藏品文化推广。自成立至今,公司已成功推出偶藏app、偶藏交易小程序、偶藏官方拍卖、偶藏鉴定等业内知名品牌,成为国内体育收藏品市场领导者。
            </div>
            <img src="./assets/new/pppp.png" class="info_img1" />
          </div>
        </div>
        <div class="info_item">
          <div class="section_title">运营模式</div>
          <div class="info_inner_item">
            <div class="info_con">
              以偶藏app和偶藏交易小程序为根基,提供用户展示、交流和交易功能,通过论坛和资料库功能以及微信社群培育和域流量,最后通过偶藏鉴定以及偶藏官方拍卖解决变现,实现生态闭环。
            </div>
            <img src="./assets/new/img1.png" class="info_img2" />
          </div>
        </div>
      </div>
    </section>
    <section class="info_box info_box2">
      <div class="description">
        重庆津南之南网络科技有限公司旗下核心产品，国内领先的专注于体育收藏品和纪念品交易、亲笔签名鉴定，以及体育收藏品文化推广等综合性服务的app,为用户提供丰富功能和内容展示。
      </div>
      <div
        class="info_title"
        style="display: flex; flex-direction: column; align-items: flex-end"
      >
        <div>ABOUT</div>
        <div>OCANG APPLICATION</div>
        <div class="line"></div>
        <div class="info_name">偶藏App及偶藏交易小程序</div>
      </div>
    </section>
    <section class="info_box info_box3">
      <img src="./assets/new/img2.png" class="phone_img" />
    </section>
    <section class="info_box info_box4">
      <div>
        <div class="info_title">
          <div>OCANG</div>
          <div>AUTHENTICITY</div>
          <div class="line"></div>
          <div class="info_name">偶藏鉴定</div>
        </div>
        <div class="section_title">什么是偶藏鉴定?</div>
        <div class="des">
          偶藏鉴定是一项亲笔签名鉴定服务,旨在解决用户对于偶像亲笔签名的鉴真需求,协助用户提高收藏价值,稳定收藏市场环境。
        </div>
      </div>
      <img src="./assets/new/img3.png" class="img3" />
    </section>
    <section class="info_box info_box5">
      <img src="./assets/new/img4.png" class="img4" />
      <div class="box_right">
        <div class="section_title">偶藏鉴定的优势</div>
        <div class="des" style="padding-top: 20px">
          在国内,偶藏鉴定在历史数据、实操经验、数据库搭建等多个维度建立起了完整的鉴定逻辑链条,从而在鉴定的准确度和权威性上都有着明显的行业优势。
        </div>
        <div class="des">
          而对标国外的鉴定公司,偶藏鉴定在鉴定速度、服务周期、用户成本、产品质量等多个维度有着明显优势。同时,在一些国外机构并不擅长的鉴定领域,偶藏鉴定则是用户更好的选择。
        </div>
        <div style="flex: 1" />
        <img src="./assets/new/img5.png" class="bottom_img" />
      </div>
    </section>
    <section class="info_box info_box6">
      <div class="section_title">特色服务-专业鉴定封盒业务</div>
      <div class="bottom_con">
        <div class="wrap_box">
          <div class="wrap_item">
            <img src="./assets/new/star.png" class="star_img" />
            <span>保护心爱的藏品,避免磨损</span>
          </div>
          <div class="wrap_item">
            <img src="./assets/new/star.png" class="star_img" />
            <span>支持在线查询,增强信任背书,提升藏品价值</span>
          </div>
          <div class="wrap_item">
            <img src="./assets/new/star.png" class="star_img" />
            <span>隔绝空气,防止签名墨迹氧化</span>
          </div>
          <div class="wrap_item">
            <img src="./assets/new/star.png" class="star_img" />
            <span>统一规格,便于码放和收藏</span>
          </div>
        </div>
        <img src="./assets/new/img6.png" class="img6" />
      </div>
    </section>
    <section class="info_box info_box7">
      <div class="top_con">
        <div
          class="info_title"
          style="display: flex; flex-direction: column; align-items: flex-end"
        >
          <div>OFFICIAL</div>
          <div>ON-LINE AUCTION</div>
          <div class="line"></div>
          <div class="info_name">偶藏官方拍卖会</div>
        </div>
        <div class="des">
          国内领先的专业体育纪念品线上拍卖会，为用户提供寄拍、挂售等多种藏品变现和购买服务。并可通过偶藏App对交易进行查询,为后续收藏或转手交易提供信任背书。
        </div>
      </div>
      <div class="bottom_con"></div>
    </section>
    <section class="info_box8">
      <div class="inner">
        <img src="./assets/new/img_8.png" class="img_8" />
        <img src="./assets/new/img8.png" class="img8" />
      </div>
    </section>
    <footer class="ft" id="contacts">
      <div class="container">
        <div class="ft__u">
          <div class="ft__l">
            <h3>Contact Us</h3>
            <p>客服QQ：2587572868</p>
            <p>E-Mail：ocang666@163.com</p>
            <p>地址：重庆市渝中区化龙桥嘉金路5号翠湖天地</p>
          </div>
          <div class="ft__r">
            <p>关注我们的社交账号</p>
            <div class="ft__r__icons">
              <ul>
                <li>
                  <img src="./assets/icons/icon-wb.png" alt="" />
                  <span>微博：偶藏</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-tt.png" alt="" />
                  <span>头条号：偶藏</span>
                </li>
                <li class="_w">
                  <img src="./assets/icons/icon-gzh.png" alt="" />
                  <span>微信公众号：偶藏</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-bj.png" alt="" />
                  <span>百家号：偶藏</span>
                </li>
              </ul>
              <ul>
                <li>
                  <img src="./assets/icons/icon-sh.png" alt="" />
                  <span>搜狐号：偶藏</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-qq.png" alt="" />
                  <span>企鹅号：偶藏</span>
                </li>
                <li class="_w">
                  <img src="./assets/icons/icon-wxq.png" alt="" />
                  <span>官方微信群：ocang66</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-dy.png" alt="" />
                  <span>抖音号：kevinoc</span>
                </li>
              </ul>
            </div>
            <!-- <div class="ft__r__qrcode">
              <span>微信公众号：</span>
              <img src="./assets/qrcode-wechat.jpg" alt />
            </div> -->
          </div>
        </div>
        <div class="ft__d">
          <span>Copyright 2020.All Rights Reserved. 偶藏 版权所有</span>
          <span>
            <router-link
              tag="a"
              target="_blank"
              :to="{ name: 'pp' }"
              style="margin-right: 20px"
              >隐私保护</router-link
            >
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302004517"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
                margin-right: 20px;
              "
            >
              <img
                src="./assets/bei.jpg"
                style="float: left; margin-right: 5px"
              />
              渝公网安备 50010302004517号
            </a>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >渝ICP备19008599</a
            >
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {
  GeneralProvisions,
  PlatformAdvocacy,
  PlatformProhibition,
  rightIcon,
} from './data'

export default {
  name: 'home',
  data() {
    return {
      value: '',
      current: 1,
      tabs: [
        { id: 1, name: '总则' },
        { id: 2, name: '平台倡导' },
        { id: 3, name: '平台禁止及不欢迎' },
      ],
      GeneralProvisions,
      PlatformAdvocacy,
      PlatformProhibition,
      rightIcon,
      currentContent: []
    }
  },
  methods: {
    setCurrent(tab) {
      this.current = tab
    },
    onMouseenter() {
      document.body.style.overflowY = 'hidden'
    },
    onMouseleave() {
      document.body.style.overflowY = 'auto'
    },
  },
}
</script>

<style lang="stylus">
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

#app {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
}

body::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

#home {
  overflow hidden;
}

.hd {
  height: 75px;
  // line-height: 75px;
  background: #000;
  // overflow: hidden;
  position: fixed;
  z-index: 999;
  width: 100%;
  transition: all 200ms;

  &::before, &::after {
    position: absolute;
    content: '';
    border-radius: 100%;
  }

  &::before {
    left: 60%;
    bottom: 0;
    width: 50px;
    height: 25px;
    background-color: #1375f8;
    border-radius: 25px 25px 0 0;
  }

  &::after {
    width: 60px;
    height: 60px;
    background: #fb0000;
    right: -30px;
    top: -30px;
  }

  .container {
    width: 80%;
    height: 75px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;

    .logo {
      width: 90px;
      height: 75px;

      &__name {
        height: 0;
      }

      &__img {
        display: block;
        width: 90px;
        height: 45px;
        margin-top: 15px;
      }
    }

    .nav {
      &__item {
        display: inline-block;
        font-size: 18px;
        color: #fff;
        margin-left: 50px;

        &__link {
          &:hover {
            color: #1375f8;
          }
        }

        .bd_nav {
          position: absolute;
          overflow: hidden;
          right: -50px;
          width: 385px;
          height: 0;
          opacity: 0;
          color: #FFFFFF;
          top: 22px;
          transition: all 50ms;
          background-color: #000;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          box-sizing: border-box;
          transition: all 300ms;
          padding-top: 24px;
          box-shadow: 0 5px 15px rgba(0,0,0,0.2)

          .bd_items {
            height: 40px;
            width: 100%;
            line-height: 30px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;
            border-bottom: 1px solid #888;

            .bd_item {
              cursor: pointer;
              color: #ffffff80;
              transition: all 200ms;
              font-size: 16px;
              margin-right: 20px;

              &:hover {
                color: #FFFFFF;
              }
            }

            .bd_item_active {
              color: #FFFFFF;
            }
          }

          .bd_con {
            position: absolute;
            right: 0;
            width: 100%;
            height: 386px;
            display: block;
            opacity: 1;
            transition: all 200ms;
            overflow-y: auto;
            box-sizing: border-box;

            .bd_con_inner {
              font-size: 14px;
              color: #ffffffbf;
              padding: 15px;

              .text {
                text-align: justify;
                word-break: break-all;
                word-wrap: break-word;
                line-height: 26px;

                .span_dot {
                  display: inline-block;
                  width: 5px;
                  height: 5px;
                  background: #ffffff57;
                  border-radius: 50%;
                  transform: translateY(-2px);
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }
            }
            .bd_con_items {
              padding: 15px 15px 0 15px;
              border-bottom: 1px solid rgba(255,255,255,0.1);

              &:last-child {
                border: none;
              }

              .bd_con_title {
                color: #ffffffe6;
                font-size: 18px;
                font-weight: 500;
                padding-bottom: 20px;
              }
              .bd_con_subtitle {
                color: #ffffffe6;
                padding-left: 20px;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                transition: all 100ms;
                padding-bottom: 15px;

                &:hover {
                  color: #ffffff;
                }

                .icon {
                  height: 16px;
                  transition: transform 200ms;
                }

                .icon_active {
                  transform: rotate(90deg);
                }
              }
              .bd_con_item_inner {
                transition: all 200ms;
                max-height: 0;
                overflow: hidden;
                box-sizing: border-box;
                opacity: 0;
                padding: 0 0 0 20px;
              }
              .bd_con_item_inner::-webkit-scrollbar {
                width: 0;
                background-color: transparent;
              }
              .bd_con_item_inner_active {
                overflow: auto;
                max-height: 3000px;
                padding-bottom: 20px;
                opacity: 1;
              }
            }
          }

          .bd_con::-webkit-scrollbar {
            width: 0;
            background-color: transparent;
          }

          &:hover {
            .bd_con {
              display: block;
              opacity: 1;
            }
          }
        }
      }

      &__item1 {
        position: relative;
        &:hover {
          .bd_nav {
            height: 450px;
            opacity: 1;
          }
        }
      }
    }
  }
}

.banner {
  position: relative;
  overflow: hidden;
  margin-top: 75px;

  &__img {
    display: block;
    width: 100%;
  }

  .check_box {
    width: 100%;
    padding: 30px 0;
    background-color: #000000;

    .inner_box {
      width: 70%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 26px;
        color: white;
      }

      .sub_title {
        font-size: 20px;
        color: white;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .check_form {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 20px;
        input {
          width: 50%;
          line-height: 40px;
          height: 40px;
          padding: 0 10px;
          border-radius: 8px;
          outline: none;
          border: none;
          font-size: 20px;
          box-sizing: border-box;
          font-weight: 600;
        }
        input:focus {
          outline: none;
          border-color: #000;
        }
        .btn {
          background: #ec6c00;
          width: 130px;
          color: #ffffff;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          height: 40px;
          margin-left: 10px;
          border-radius: 8px;
          cursor: pointer;
          transition: all 200ms;

          &:hover {
            background: #f67508;
          }
        }
      }
    }
  }
}

.ft {
  background: #343434;
  color: #ffffff;
  padding-bottom: 50px;
  font-size: 14px;

  .container {
    width: 90%;
    margin: 0 auto;
  }

  &__u {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
  }

  &__d {
    border-top: 1px solid #ffffff;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    span {
      a:hover {
        text-decoration: underline;
        // cursor pointer;
      }
    }
  }

  &__l {
    h3 {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 15px;
    }
  }

  &__r {
    p {
      text-align: right;
      margin-bottom: 40px;
    }

    &__icons {
      ul {
        display: flex;
        margin-bottom: 10px;
        li {
          display: flex;
          align-items: center;
          width: 150px;
          font-size: 12px;
          margin-left: 20px;

          img {
            display: block;
            height: 80%;
            margin-right: 10px;
          }
        }
        li._w {
            width: 180px;
        }
      }
    }

    // &__qrcode {
    //   display: flex;
    //   align-items: center;

    //   span {
    //     padding-left: 20px;
    //   }

    //   img {
    //     // display block
    //     width: 50px;
    //   }
    // }
  }
}

.info_box {
  margin: auto;
  padding-top: 120px;

  .section_title {
    height: 30px;
    background-image: linear-gradient(to right, #ec6c00, #FFFFFF);
    font-size: 24px;
    color: #FFFFFF;
    line-height: 30px;
    padding-left: 20px;
  }

  .img {
    display: block;
    margin: 0;
    padding: 0;
  }

  .info_title {
    font-size: 28px;
    font-weight: bold;
    color: #ec6c00;
  }

  .info_name {
    font-size: 30px;
    color: #221715;
    font-weight: bold;
  }

  .line {
    height: 4px;
    width: 28px;
    background-color: #ec6c00;
    border-radius: 2px;
    margin: 5px 0;
  }

  .info_bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    flex-wrap: wrap;
    .info_item {
      box-sizing: border-box;

      .info_inner_item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 20px;
      }


      .info_con {
        font-size: 14px;
        color: #463c3d;
        line-height: 28px;
        text-align: justify;
        word-break: break-all;
        word-wrap: break-word;
        text-indent: 28px;
        letter-spacing: 1px;
        flex: 1;
        padding-right: 50px;
      }

      .info_img1 {
        width: 170px;
        border: 1px solid #dcdcdc;
        padding: 20px;
      }

      .info_img2 {
        width: 340px;
        margin-top: -150px;
      }

      &:nth-child(1) {
        width: 45%;
        padding-right: 25px;

      }
      &:nth-child(2) {
        width: 55%;
        padding-left: 25px;
      }

      .section_title  {
        width: 250px;
      }
    }
  }

}

.info_box2 {
  display: flex;
  justify-content: flex-end;
  padding-top: 80px;
  align-items: flex-end;
  .description {
    flex: 1;
    font-size: 14px;
    color: #463c3d;
    text-align: justify;
    word-break: break-all;
    word-wrap: break-word;
    padding-right: 20px;
    padding-top: 20px;
    text-indent: 28px;
    padding: 0 180px;
    line-height: 28px;
    letter-spacing: 1px;
  }
}

.info_box3 {
  padding-top: 90px;
  .phone_img {
    width: 100%;
  }
}

.info_box4 {
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .section_title {
    width: 250px;
  }
  .des {
    font-size: 14px;
    color: #463c3d;
    text-align: justify;
    word-break: break-all;
    word-wrap: break-word;
    padding-top: 20px;
    text-indent: 28px;
    padding-right: 100px;
    letter-spacing: 1px;
    line-height: 28px;
  }
  .img3 {
    width: 580px;
  }
}

.info_box5 {
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
  .box_right {
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .section_title {
      width: 250px;
    }
  }
  .des {
    font-size: 14px;
    color: #463c3d;
    text-align: justify;
    word-break: break-all;
    word-wrap: break-word;
    text-indent: 28px;
    padding-left: 100px;
    letter-spacing: 1px;
    line-height: 28px;
  }
  .img4 {
    width: 570px;
  }
}

.info_box6 {
  padding-top: 90px;

  .section_title {
    width: 400px;
  }
  .bottom_con {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wrap_box {
      display: flex;
      flex-wrap: wrap;
      padding-left: 100px;
      box-sizing: border-box;
      .wrap_item {
        width: 50%;
        font-size: 14px;
        color: #463c3d;
        padding-top: 60px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        .star_img {
          width: 22px;
          height: 22px;
          margin-top: -2px;
        }
      }
    }
    .img6 {
      width: 360px;
    }
  }
}

.info_box7 {
  .top_con {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .des {
      width: 580px;
      font-size: 14px;
      color: #463c3d;
      text-align: justify;
      word-break: break-all;
      word-wrap: break-word;
      text-indent: 28px;
      padding-top: 50px;
      line-height: 28px;
      letter-spacing: 1px;
    }
  }
}

.info_box8 {
  width: 100%;
  height: 260px;
  background-color: #ea6b0a;
  margin-top: 50px;
  margin-bottom: 120px;

  .inner {
    width: 1200px;
    margin: auto;
    position: relative;
    .img_8 {
      width: 300px;
      position: absolute;
      top: -300px;
    }
    .img8 {
      width: 600px;
      position: absolute;
      right: 0;
    }
  }
}



@media (max-width:1199px) {
  .info_box {
    width: 960px;
    padding-top: 90px;

    .info_bottom {

      .info_item {

        .info_inner_item {
          align-items: flex-start;
        }

        .info_con {
          width: 250px;
          font-size: 14px;
          text-indent: 28px;
        }

        .info_img1 {
          width: 170px;
          margin-top: 30px;
        }

        .info_img2 {
          width: 200px;
          margin-top: -20px;
        }

        &:nth-child(1) {
          width: 430px;
          .info_con {
            width: 240px;
          }
        }
        &:nth-child(2) {
          width: 500px;
        }

        .section_title  {
          width: 250px;
        }
      }
    }
  }

  .info_box2 {
    padding-top: 60px;
    .description {
      padding: 0 100px;
    }
  }

  .info_box3 {
    padding-top: 60px;
    .phone_img {
      width: 100%;
    }
  }

  .info_box4 {
    padding-top: 60px;

    .section_title {
      width: 250px;
      margin-top: 20px;
    }
    .des {
      padding-right: 100px;
    }
    .img3 {
      width: 400px;
    }
  }

  .info_box5 {
    padding-top: 60px;
    .box_right {
      height: 315px;
    }
    .des {
      padding-left: 100px;
    }
    .img4 {
      width: 400px;
    }
    .bottom_img {
      padding-left: 100px;
    }
  }

  .info_box6 {
    padding-top: 60px;
    .bottom_con {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrap_box {
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        box-sizing: border-box;
        .wrap_item {
          padding-top: 40px;
          padding-left: 0;
          .star_img {
            width: 22px;
            height: 22px;
            margin-top: -2px;
          }
        }
      }
      .img6 {
        width: 260px;
      }
    }
  }

  .info_box8 {
    width: 100%;
    height: 200px;
    background-color: #ea6b0a;
    margin-top: 50px;
    margin-bottom: 120px;

    .inner {
      width: 960px;
      margin: auto;
      position: relative;
      .img_8 {
        width: 250px;
        position: absolute;
        top: -250px;
      }
      .img8 {
        width: auto;
        height: 190px;
        position: absolute;
        right: 0;
      }
    }
  }
}


@media (min-width:1200px) {
  .banner{
    .check_box  {
      .inner_box {
        width: 1200px;
        .check_form {
          input {
            width: 400px;
          }
        }
      }
    }
  }

  .info_box {
    width: 1200px;
  }
}

@media (min-width:1300px) {
  .banner{
    .check_box  {
      .inner_box {
        width: 1200px;
        .check_form {
          input {
            width: 400px;
          }
        }
      }
    }
  }

  .info_box {
    width: 80%;
  }
  .info_box8 {
    .inner {
      width: 80%;
    }
  }

  .ft .container {
    width: 1200px;
    margin: 0 auto;
  }
}

@media (max-width:900px) {
  .hd {
    .container {
      width: 100%;
      padding: 0 20px;
      .logo {
        width: auto;
        &__img {
          width: auto;
          height: 32px;
          margin-top: 18px;
        }
      }
      .nav__item{
        font-size: 14px;
        margin-left: 20px;
      }
    }
  }
  .banner .container{
    width: 90%;
    top: 10px;
    margin-left: -45%;

    .banner__p {
      width: 30%;
    }
    .banner__c {
      width: 70%;
      padding-top: 16px;
      &__slogan {
        width: 50%;
      }
      &__desc {
        font-size: 12px;
        line-height: 1.2em;
        padding: 10px 0;
      }
      &__dl{
        justify-content: flex-start;

        &__ios{
          width: 15%
          margin-right: 30px;
        }
        &__android{
          width: 15%
        }
      }
    }
  }
}
</style>
